$(document).ready(function(){
	$(window).load(function(){

		//mega menu class
		$('li.menu-item-object-projects').append($('div#project-mega-menu'));


		//level menu class
		$('.sub-menu .sub-menu').addClass('third-lvl')
		$('.sub-menu .sub-menu .sub-menu').removeClass('third-lvl')
		$('.sub-menu .sub-menu .sub-menu').addClass('fourth-lvl')

		// file upload starts here 
		$('a#file-btn-custom').on('click',function(){
			$('input#input_2_14').click();
		});

        $('input#input_2_14').change(function(e){
            var fileName = e.target.files[0].name;
            $('span.file-upload-text').text(fileName);
        });
		// file upload ends here

		// project gallery slider starts here
		$('.gallery-image').on('click', function(){
			$('.gallery-image').removeClass('active');
			$(this).addClass('active');
			var x = $(this).find('img').attr('src');
			$('.primary-image-wrapper img').attr('src',x);
		});
		// project gallery ends here

		// front page slider starts here
		$('#front-page-slider').slick({
			dots: false,
			infinite: true,
			speed: 800,
			autoplay: true,
			slidesToScroll: 1,
			prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
			nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>'
		});
		// front page slider ends here

		// service slider starts here
		$('#service-slider').slick({
			autoplay: true,
			dots: false,
			infinite: true,
			slidesToScroll: 1,
			slidesToShow: 4,
			speed: 800,
			prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
			nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 3,
					infinite: true,
				  }
				},
				{
				  breakpoint: 769,
				  settings: {
					slidesToShow: 2,
				  }
				},
				{
				  breakpoint: 480,
				  settings: {
					slidesToShow: 1,
				  }
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			  ]
		});
		// service slider ends here

		// sector slider starts here 
		$('#sector-slider').slick({
			autoplay: true,
			dots: false,
			infinite: true,
			slidesToScroll: 1,
			slidesToShow: 3,
			speed: 800,
			prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
			nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
			responsive: [
				{
				  breakpoint: 769,
				  settings: {
					slidesToShow: 2,
				  }
				},
				{
				  breakpoint: 480,
				  settings: {
					slidesToShow: 1,
				  }
				}
			  ]
		});
		// sector slider ends here

		// region slider starts here 
		$('#region-slider').slick({
			autoplay: true,
			dots: false,
			infinite: true,
			slidesToScroll: 1,
			slidesToShow: 3,
			speed: 800,
			prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
			nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
			responsive: [
				{
				  breakpoint: 769,
				  settings: {
					slidesToShow: 2,
				  }
				},
				{
				  breakpoint: 480,
				  settings: {
					slidesToShow: 1,
				  }
				}
			  ]
		});
		// region slider ends here

		// expertise slider starts here 
		$('#expertise-slider').slick({
			autoplay: true,
			dots: false,
			infinite: true,
			slidesToScroll: 1,
			slidesToShow: 3,
			speed: 800,
			prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
			nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
			responsive: [
				{
				  breakpoint: 769,
				  settings: {
					slidesToShow: 2,
				  }
				},
				{
				  breakpoint: 480,
				  settings: {
					slidesToShow: 1,
				  }
				}
			  ]
		});
		// expertise slider ends here

		// featured projects function starts here
		$('.sector-btn').on('click', function(){
			$(this).addClass('active');
			$('.region-btn').removeClass('active');
			$('.expertise-btn').removeClass('active');

			$('#sector-slider').fadeIn();
			$('#region-slider').hide();
			$('#expertise-slider').hide();
		});

		$('.region-btn').on('click', function(){
			$(this).addClass('active');
			$('.sector-btn').removeClass('active');
			$('.expertise-btn').removeClass('active');

			$('#sector-slider').hide();
			$('#region-slider').fadeIn();
			$('#expertise-slider').hide();
		});

		$('.expertise-btn').on('click', function(){
			$(this).addClass('active');
			$('.sector-btn').removeClass('active');
			$('.region-btn').removeClass('active');

			$('#sector-slider').hide();
			$('#region-slider').hide();
			$('#expertise-slider').fadeIn();
		});

		$('#region-slider').hide();
		$('#expertise-slider').hide();

		// featured projects function ends here



		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

		$('.menu-container .menu-contain').css({
			'padding-bottom' : $quickLinks,
			'padding-top' : $navHeight
		});

		$(document).on('click', '.mobile-menu.notAnimated', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$(this).removeClass('notAnimated').addClass('animated');
			$('.menu-container').addClass('show');
			setTimeout(function(){
				$('.menu-container').addClass('animate');
			}, 500);
		});

		$(document).on('click', '.mobile-menu.animated', function(){
			$(this).removeClass('animated').addClass('notAnimated');

			$('.menu-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.menu-container').removeClass('show');
			}, 500);

			setTimeout(function(){
				$('.menu-container .menu-contain nav ul li .sub-menu').removeClass('show');
			}, 700);

		});

		$(document).on('click', '.submenu-button', function(){
			$(this).parent('li').find(' > .sub-menu').addClass('show');
		});
		$(document).on('click', '.submenu-close-button', function(){
			$(this).parent('li').parent('ul').removeClass('show');
		});

		$(document).on('click', 'a[href^="mailto:"].main', function(e){
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.mailto-container').addClass('show');
			setTimeout(function(){
				$('.mailto-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.mailto-close-button', function(){
			$('.mailto-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.mailto-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '.search-btn', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});
			$('.search-form-template').addClass('show');
			setTimeout(function(){
				$('.search-form-template').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.search-close-button', function(){
			$('body, html').removeAttr('style');
			$('.search-form-template').removeClass('show');
			setTimeout(function(){
				$('.search-form-template').removeClass('animate');
			}, 300);
		});

		$(document).on('click', '.testimonial-item-contain', function(){
			$.ajax({
				url : $('.ajaxlocation').data('ajaxlocation'),
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'request_testimonial',
					id : $(this).data('id')
				},
				beforeSend: function(){
					$('body, html').css({
						'overflow' : 'hidden'
					});
					$('.popup-modal-container').addClass('show');
					$('html').addClass('popupshow');
				},
				success: function(response) {
					
					setTimeout(function(){
						$('.popup-modal-container').addClass('animate');
					}, 300);
					$('.popup-modal-container .popup-body').html(response.content);
			
				}
			});
		});

		$(document).on('click', '.popup-close', function(){
			$('body, html').removeAttr('style');
			$('header.site-header.push-top').removeAttr('style');
			$('.popup-modal-container').removeClass('show');
			$('html').removeClass('popupshow');
			setTimeout(function(){
				$('.popup-modal-container').removeClass('animate');
			}, 300);
		});


		// $(document).on('click', '.apply-btn', function(e){
		// 	var position = $(this).data('position');
		// 	e.preventDefault();
		// 	$('body, html').css({
		// 		'overflow' : 'hidden'
		// 	});

		// 	$('.career-container .yourPosition input').val(position);
		// 	$('.career-container').addClass('show');
		// 	setTimeout(function(){
		// 		$('.career-container').addClass('animate');
		// 	}, 300);
		// });

		$(document).on('click', '.career-close-button', function(){
			$('.career-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.career-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '#resumeBtn', function(){
			var input = $('.resume-container .resume input');
			input.click();
		});

		$('.resume-container .resume input').change(function(e){
			var FileName = e.target.files[0];
			$('#resumeBtn').addClass('active').text(FileName.name);
		});

		$(document).on('click', '.expand-term', function(e){
			e.preventDefault();
			$(this).toggleClass('fa-caret-down').toggleClass('fa-caret-up');
			$(this).parent('a').parent('.parent-term').first().toggleClass('active');
			$(this).parent('a').parent('.parent-term').find('.chid-term').first().toggleClass('active');
		});

		$(document).on('click', '.expand-selection-term', function(e){
			$('.archive-sidebar .term-container').toggleClass('active');
		});

		$(document).on('click', '.parent-backdrop .backdrop', function(){
			$('body, html').removeAttr('style');
			$(this).parent('.parent-backdrop').removeClass('show');
			setTimeout(function(){
				$(this).parent('.parent-backdrop').removeClass('animate');
			}, 300);
		});

	});
});